import React, { Component } from 'react';
import './Button.css';

class Button extends Component {
  render() {
    const { text } = this.props
    return(
      <div><button>{text}</button></div>
    )
  }
}

export default Button