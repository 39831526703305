import React, { Component } from 'react';
import './ShortenedUrlListItem.css';

class ShortenedUrlListItem extends Component{
  _getUrlToRemove = (e) => {
    this.props.removeUrl(e.target.getAttribute('data-slug'))
  }

  _copyShortenedUrl = (e) => {
    let dummy = document.createElement('input'),
    URLtoCopy = e.target.getAttribute('data-shortenedUrl');
    document.body.appendChild(dummy);
    dummy.value = URLtoCopy;

    let range, selection;

    range = document.createRange();
    range.selectNodeContents(dummy);
    selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    dummy.setSelectionRange(0, 999999)

    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);

    alert("Copied to clipboard! " + URLtoCopy)
  }

  render() {
    const { url, shortenedUrl, slug } = this.props;
    return(
      <div className="shorted-url-list-item">
        <div>
          <span>{url}</span>
          <span>{shortenedUrl}</span>
        </div>
        <div>
          <span><ion-icon name="copy-outline" onClick={this._copyShortenedUrl} data-shortenedUrl={shortenedUrl}></ion-icon></span>
          <span><ion-icon name="trash-outline" onClick={this._getUrlToRemove} data-slug={slug}></ion-icon></span>
        </div>
      </div>
    )
  }
}

export default ShortenedUrlListItem
