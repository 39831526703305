import React, { Component } from 'react';
import './InputText.css';

class InputText extends Component {
  _onChange = (e) => {
    const { name, value } = e.target;
    this.props.getValue(name, value)
  }

  render() {
    const { type, label, id, value, placeholder, error, required } = this.props
    return(
      <div className={error && error.length > 0 ? "input-text error" : "input-text"} >
        <input type={type} name={id} id={id} value={value} placeholder={placeholder} onChange={this._onChange} required={required ? true : false} />
        <label htmlFor={id}>{label}</label>
        {error && error.length > 0 ? <small className="error-msg">{error}</small> : ""}
      </div>
    )
  }
}

export default InputText