export async function getUrlList() {
  const listUrls = await fetch('http://api.bely.me/links', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'GB-Access-Token': 'b1b20e458d4f37bd4008ed7e36253c57'
    }
  })
  .then(response => response.json())
  return listUrls;
}

export async function getShortenUrl(url, slug) {
  const newUrl = await fetch('http://api.bely.me/links', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'GB-Access-Token': 'b1b20e458d4f37bd4008ed7e36253c57'
    },
    body: JSON.stringify({
      url: url,
      slug: slug
    })
  })
  .then(response => response.json())
  return newUrl;
}

export async function removeUrl(slug) {
  const removedUrl = await fetch('http://api.bely.me/links/' + slug, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'GB-Access-Token': 'b1b20e458d4f37bd4008ed7e36253c57'
    }
  })
  .then(data => {return data})
  return removedUrl;
}