import React, { Component } from 'react';
import './ShortenUrl.css';

import InputText from '../../components/InputText/InputText';
import ShortenedUrlListItem from '../../components/ShortenedUrlListItem/ShortenedUrlListItem';
import Button from '../../components/Button/Button';
import { getUrlList, getShortenUrl, removeUrl } from '../../services/bgapi'

class ShortenUrl extends Component {
  constructor() {
    super();
    this.state = {
      url: "",
      slug: "",
      shortUrlArray: [],
      error: "",
      loading: false
    }
  }

  componentDidMount() {
    this._fetchUrls()
  }

  _fetchUrls = async () => {
    this.setState({ loading: true })
    try {
      const urlList = await getUrlList()
      this.setState({
        shortUrlArray: urlList,
        loading: false
      })
    } catch (error) {
      this.setState({
        error: "Failed to load. Please reload the page.",
        loading: false
      })
    }
  }

  _getValue = (name, value) => {
    this.setState({ [name]: value });
  }

  _validateURL = (urlPassed) => {
    let urlRegex = '^(?!mailto:)(?:(?:http|https|ftp)://)?(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
    let urlT = new RegExp(urlRegex, 'i');
    if(!(urlPassed.length < 2083 && urlT.test(urlPassed))) {
      this.setState({ error: "Invalid URL"})
      return false;
    }else{
      this.setState({ error: "" })
      return true
    }
  }

  _submitUrl = async (e) => {
    e.preventDefault();
    const { url, slug, shortUrlArray } = this.state
    if(this._validateURL(url)){
      try {
        const newUrlAdded = await getShortenUrl(url, slug)
        if(newUrlAdded.errors){
          this.setState({
            error: newUrlAdded.errors.url ? newUrlAdded.errors.url : newUrlAdded.errors.slug
          })
        }else{
          this.setState({
            shortUrlArray: [newUrlAdded].concat(shortUrlArray),
            error: "",
            url: "",
            slug: ""
          })
        }
      } catch (error) {
        this.setState({
          error: "Failed to load. Please reload the page."
        })
      }
    }
  }

  _removeUrl = async (slug) => {
    const { shortUrlArray } = this.state
    const ask = window.confirm(
      "Remove this URL ends with " + slug + "?"
    );
    if (ask) {
      try {
        const removedUrl = await removeUrl(slug)
        if(!removedUrl){
          this.setState({
            error: "Delete failed. Please try again."
          })
        }else{
          const newArray = shortUrlArray.filter((item) => {
            return item.slug !== slug
          });
          this.setState({
            shortUrlArray: newArray,
            error: "",
            url: "",
            slug: ""
          })
        }
      } catch (error) {
        this.setState({
          error: "Failed to load. Please reload the page."
        })
      }
    }
  }

  render() {
    const { shortUrlArray, error, url, slug, loading } = this.state
    const urlList = shortUrlArray.map((item, index) => {
      return <ShortenedUrlListItem key={index} url={item.url} shortenedUrl={item.short_url} slug={item.slug} removeUrl={this._removeUrl} /> 
    })
    return (
      <div>
        <form onSubmit={this._submitUrl}>
          <div className="input-url animate-fade-in-up">
            <InputText type="text" label="Link" id="url" value={url} placeholder="e.g. GoldBelly.com" getValue={this._getValue} error={error} required />
            <InputText type="text" label="Custom slug(optional)" id="slug" value={slug} placeholder="e.g. news" getValue={this._getValue} />
            <Button text="Shorten URL" />
          </div>
        </form>

        <div className="shorted-url-list-wrapper animate-fade-in-up" style={{ animationDelay: "0.2s"}}>
          {loading ? <p>Loading...</p> : <p>{shortUrlArray &&  shortUrlArray.length + " URL" + (shortUrlArray.length > 1 ? "s" : "") + " Generated"}</p>}
          {shortUrlArray && urlList}
        </div>
      </div>
    )
  }
}

export default ShortenUrl